import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { v4 as uuidv4 } from 'uuid'

import { queryClient } from '../../../utils/react-query'
import { Infobox, Spinner } from '../../../components'
import { useDynamicInfobox } from '../../../hooks/useDynamicInfobox'
import { QueryClientProvider } from '@tanstack/react-query'
import { fireEvent } from '@/utils/satu-event'
import { useSatuEvent } from '@/hooks/useSatuEvent'

const EVENT_NAME = 'hiedu_sister_infobox_v3'

const LandingInfobox = (props) => {
  const {
    'is-login': isLogin,
    'user-id': userId = '',
    'user-name': userName = '',
    'user-email': userEmail = '',
    'user-nidn': userNIDN = '',
    'user-pt': userPT = '',
  } = props
  const { infobox, renderVariant } = useDynamicInfobox('LANDING_INFOBOX_CONFIG')
  const { config: eventConfig } = useSatuEvent()

  const onFireEvent = (props) => {
    const { action, category, config } = props

    let body = {
      event_name: EVENT_NAME,
      data: JSON.stringify({
        id: uuidv4(),
        timestamp: new Date(),
        event_category: category,
        event_action: action,
        event_label_is_login: isLogin.toLowerCase() === 'true',
        event_label_title: config.title,
        event_label_user_id: userId,
        event_label_user_name: userName,
        event_label_nidn: userNIDN,
        event_label_email: userEmail,
        event_label_nama_pt: userPT,
        event_label_user_browser_id: localStorage.getItem('infobox_browser_id'),
      }),
    }
    return fireEvent(eventConfig, body)
  }

  const onClose = async (config) => {
    await onFireEvent({
      config,
      action: 'infobox_clicked',
      category: 'Non-Login',
    })

    location.href = config.link
  }

  useEffect(() => {
    if (infobox)
      infobox?.data?.map((config) =>
        onFireEvent({
          config,
          action: 'infobox_viewed',
          category: 'Non-Login',
        }),
      )

    if (!localStorage.getItem('infobox_browser_id'))
      localStorage.setItem('infobox_browser_id', uuidv4())
  }, [infobox?.data])

  if (!infobox) {
    return <Spinner size={45} />
  }

  return infobox?.data?.map((config) => (
    <Infobox
      key={config.title}
      fill={renderVariant(config.variants).fill}
      showIcon={true}
      title={config.title}
      variant={renderVariant(config.variants).type}
      showAction={config.link}
      labelBtn={config.label || 'Isi Sekarang'}
      onClickAction={() => onClose(config)}
    >
      {config.description}
    </Infobox>
  ))
}

const App = (props) => (
  <QueryClientProvider client={queryClient}>
    <LandingInfobox {...props} />
  </QueryClientProvider>
)

const app = document.getElementById('announcement-alert')
let obj = {}
for (let i = 0, atts = app.attributes, n = atts.length, arr = []; i < n; i++) {
  obj[atts[i].nodeName] = atts[i].nodeValue
}

if (app) {
  const root = createRoot(app)
  root.render(<App {...obj} />)
}
